import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import loadable from '@loadable/component';

const Grid = loadable(() => import('../components/grid'));
const CardWithoutImage = loadable(() => import('../components/card-without-image'));

const Projects = ({ data }) => {
  const metaData = {
    title: 'Проекти'
  };
  return (
    <Layout metaData={metaData}>
      <Grid mode="dark">
        {data.allMarkdownRemark.edges.map((item, i) => {
          const data = item.node.frontmatter;
          return (
            <CardWithoutImage
              key={`key${i}`}
              title={data.title}
              bg={data.coverImage.childImageSharp.fluid.src}
              url={item.node.fields.slug.slice(9, item.node.fields.slug.length - 1)}
            />
          );
        })}
      </Grid>
    </Layout>
  );
};

export default Projects;

export const query = graphql`
  query prjCategories {
    allMarkdownRemark(
      filter: { frontmatter: { category: { eq: true } } }
      sort: { fields: [frontmatter___index], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            index
            title
            coverImage {
              ...largeImage
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
